<template>
  <div class="bar">
    <div :id="id" ref="myEchart" :style="{width: width,height:height}"></div>
  </div>

</template>

<script>
  export default {
    name: "bar",
    data(){
      return {
        chart:undefined
      }
    },
    mounted() {
      let _this=this;
      this.echarts_init()
      setTimeout( () => {
        window.addEventListener("resize", () => {
          this.chart.resize();
        })
      },200)
    },
    props:{
      width: {
        type:String,
        default: '100%'
      },
      height: {
        type:String,
        default: '100%'
      },
      id:{
        type:String,
        default: 'pies'
      },
      trendsData:{
        type:Object,
        default: {}
      }
    },
    beforeDestroy() {
      if(this.chart) this.chart.dispose()
      window.removeEventListener("resize", () => {
        this.chart.resize();
      })
    },
    watch:{
      trendsData:{
        handler(){
          this.echarts_init()
        },
        deep:true
      }
    },
    methods:{
      echarts_init(){
        if(this.chart) this.chart.dispose()
        // 获取echarts的容器
        this.chart = echarts.init(document.getElementById(this.id));

        var option = {
          color: ['#4a92ff', '#3a36ef', '#0a85c8'],
          backgroundColor: '#F4F8FF',
          grid:{
            bottom: '18%'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            data: ['申请量'],
            selectedMode: false,
            textStyle:{
              color: '#1F46D7'
            },
            top: '7%',
            left: '3%'
          },
          xAxis: [
            {
              type: 'category',
              data: this.trendsData.legendYear,
              boundaryGap: false,
              axisPointer: {
                type: 'shadow'
              },
              nameTextStyle:{
                color: '#ffffff'
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#384269'   //这里用参数代替了
                }
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              min: 0,
              show: true,
              boundaryGap: false,
              nameTextStyle:{
                color: '#ffffff'
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false,
                textStyle: {
                  color: '#ffffff'   //这里用参数代替了
                },
                formatter: '{value} '
              },
            },
            {
              type: 'value',
              name: '',
              // splitNumber: 5,
              textStyle:{
                color: '#ffffff'
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false,
                textStyle: {
                  color: '#ffffff'   //这里用参数代替了
                },
                formatter: '{value} %'
              },
            }
          ],
          series: [
            {
              name: '申请量',
              type: 'line',
              symbol: 'none',
              showSymbol: false,
              clip: true,
              yAxisIndex: 1,
              data: this.trendsData.applications,
              areaStyle: {},
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#6188FD",
                    width:3
                  }
                }
              }
            }
          ]
        };
        this.chart.setOption(option);
      }
    }
  }
</script>

<style scoped>

</style>
