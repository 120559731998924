<template>
  <div class="queryAnalysisReport">
    <svg class="downloadButton" @click="downloadAnalysis" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#228;&#184;&#139;&#232;&#189;&#189; (1) 1">
        <path id="Vector" d="M0.90625 10.9102C0.90625 13.5854 1.96898 16.151 3.86064 18.0427C5.7523 19.9343 8.31795 20.9971 10.9932 20.9971C13.6684 20.9971 16.234 19.9343 18.1257 18.0427C20.0174 16.151 21.0801 13.5854 21.0801 10.9102C21.0801 8.23494 20.0174 5.66929 18.1257 3.77763C16.234 1.88597 13.6684 0.823242 10.9932 0.823242C8.31795 0.823242 5.7523 1.88597 3.86064 3.77763C1.96898 5.66929 0.90625 8.23494 0.90625 10.9102Z" fill="#0F50BB"/>
        <path id="Vector_2" d="M15.5242 10.6953C15.868 11.0391 15.868 11.5955 15.5242 11.9393L11.6033 15.8602C11.2596 16.2039 10.7031 16.2039 10.3594 15.8602C10.0156 15.5164 10.0156 14.96 10.3594 14.6162L14.2803 10.6953C14.624 10.3516 15.1805 10.3516 15.5242 10.6953Z" fill="white"/>
        <path id="Vector_3" d="M6.46094 10.6953C6.80469 10.3516 7.36113 10.3516 7.70488 10.6953L11.6258 14.6162C11.9695 14.96 11.9695 15.5164 11.6258 15.8602C11.282 16.2039 10.7256 16.2039 10.3818 15.8602L6.46094 11.9393C6.11719 11.5955 6.11719 11.0391 6.46094 10.6953Z" fill="white"/>
        <path id="Vector_4" d="M11.0059 5.48535C11.4914 5.48535 11.8867 5.87852 11.8867 6.36621V15.2156C11.8867 15.7012 11.4936 16.0965 11.0059 16.0965C10.5203 16.0965 10.125 15.7033 10.125 15.2156V6.36621C10.1271 5.87852 10.5203 5.48535 11.0059 5.48535Z" fill="white"/>
      </g>
    </svg>
    <div id="applicantAnalysis" v-if="reportType == 0">
      <div class="analysisTitle">【{{queryKeyword}}】商标申请分析报告</div>

      <div class="analysisContentItem" style="padding-bottom: 0">
        <p class="analysisContentTitle">商标申请总量</p>
        <div class="trademarkAgentTotal" style="border-radius: 5px; overflow: hidden">
          <svg width="358" height="80" viewBox="0 0 358 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 895">
              <g id="Group 894">
                <rect id="Rectangle 1266" width="358" height="80" rx="5" fill="url(#paint0_linear_602_906)"/>
                <g id="Mask group">
                  <mask id="mask0_602_906" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="358" height="80">
                    <rect id="Rectangle 1264" width="358" height="80" rx="5" fill="#CBD6FD"/>
                  </mask>
                  <g mask="url(#mask0_602_906)">
                    <path id="Rectangle 1269" d="M8.60742 -164.277L8.6074 164.971L-32.3575 164.971L-32.3575 -164.277L8.60742 -164.277Z" fill="#0F50BB"/>
                  </g>
                </g>
              </g>
              <rect id="Rectangle 1268" x="278" y="21" width="47" height="42" rx="8" fill="#BBC9FC"/>
              <g id="&#231;&#148;&#179;&#232;&#175;&#183; 2">
                <path id="Vector" d="M306.812 41.3438L304.156 38.6875L310.719 32.125H291.5C290.406 32.125 289.625 32.9063 289.625 34V49C289.625 50.0938 290.406 50.875 291.5 50.875H311.5C312.594 50.875 313.375 50.0938 313.375 49V34.7813L306.812 41.3438ZM303.531 39.4687C303.531 39.1562 303.844 39.1562 303.844 39.4687L306.031 41.5V41.8125L303.375 42.125C303.375 42.125 303.063 42.125 303.063 41.8125L303.531 39.4687ZM293.062 35.5625H298.844C299.313 35.5625 299.625 35.875 299.625 36.3437C299.625 36.8125 299.313 37.125 298.844 37.125H293.062C292.594 37.125 292.281 36.8125 292.281 36.3437C292.281 36.0312 292.906 35.5625 293.062 35.5625ZM293.062 40.5625H298.844C299.313 40.5625 299.625 40.875 299.625 41.3438C299.625 41.8125 299.313 42.125 298.844 42.125H293.062C292.75 42.125 292.281 41.8125 292.281 41.3438C292.281 40.875 292.906 40.5625 293.062 40.5625ZM309.156 47.2812H293.062C292.594 47.2812 292.281 46.9687 292.281 46.5C292.281 46.0312 292.594 45.7187 293.062 45.7187H309.156C309.625 45.7187 309.938 46.0312 309.938 46.5C309.938 47.125 309.625 47.2812 309.156 47.2812Z" fill="white"/>
              </g>
            </g>
            <defs>
              <linearGradient id="paint0_linear_602_906" x1="-14.5" y1="40.5063" x2="358" y2="40.0126" gradientUnits="userSpaceOnUse">
                <stop stop-color="#123694"/>
                <stop offset="0.125673" stop-color="#CDD9F9" stop-opacity="0.8"/>
                <stop offset="1" stop-color="#FBFDFF"/>
              </linearGradient>
            </defs>
          </svg>
          <span>{{ appCount }}</span>
        </div>
        <p style="font-size: 12px; margin: 10px 0 0 0; color: #666666">
          注:统计数据截止到2024年上半年
        </p>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">中国大陆申请量趋势图</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="applicantAnnualApplicationTrend"/>
        </div>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">重点商标分析</p>
        <el-row style="border-radius: 5px; overflow: hidden">
          <el-table
            :data = applicantKeyTrademarkTableData
            :height="heightFlag ? '' : 300 "
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
            :cell-style = "{'padding':'5px 0'}"
          >
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="序号"
            />
            <el-table-column
              align="center"
              prop="tmName"
              label="商标名称"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              prop="imageUrl"
              label="商标图标"
            >
              <template slot-scope="scope">
                <img
                  style="max-width: 50px;max-height: 50px; margin: 0 10px"
                  :src="scope.row.imageUrl == null ? null : 'https://tmimage.lanternfish.cn' + scope.row.imageUrl"
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="tmName"
              label="类别"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{scope.row.niceSet.join(";")}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row style="margin-top: 10px; background-color: #F3F7FE; border-radius: 5px; overflow: hidden">
          <p class="analysisContentTitle2">重点商标统计</p>
          <div class="analysisContentChart" id="applicantKeyTrademarkStatistic"/>
        </el-row>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">商标行业分析</p>

        <el-row style="border-radius: 5px; overflow: hidden">
          <el-table
            :data = applicantKeyBussinessData
            :height="heightFlag ? '' : 220 "
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
            :cell-style = "{'padding':'5px 0'}"
          >
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="序号"
            />
            <el-table-column
              align="center"
              prop="nice"
              label="类别"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              prop="appCount"
              label="数量"
            />
            <el-table-column
              align="center"
              prop="niceName"
              label="服务名称"
              show-overflow-tooltip
            />
          </el-table>
        </el-row>

        <el-row style="margin-top: 10px">
          <p class="analysisContentTitle2">重点行业统计</p>
          <div style="border-radius: 5px; overflow: hidden">
            <div class="analysisContentChart" id="applicantKeyBussinessStatistic"/>
          </div>
        </el-row>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">代理机构分析</p>

        <el-row style="border-radius: 5px; overflow: hidden">
          <el-table
            :data = applicantAgentData
            :height="heightFlag ? '' : 220 "
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
            :cell-style = "{'padding':'5px 0'}"
          >
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="序号"
            />
            <el-table-column
              align="center"
              prop="agentName"
              width="110"
              label="代理机构名称"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              prop="appSelfCount"
              width="150"
              label="本申请人代理商标量"
            />
            <el-table-column
              align="center"
              prop="appCount"
              label="总代理量"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              prop="agentAddress"
              label="地址"
              show-overflow-tooltip
            />
          </el-table>
        </el-row>

        <el-row style="margin-top: 10px">
          <p class="analysisContentTitle2">代理机构统计</p>
          <div class="analysisContentChart" id="applicantAgentStatistic"/>
        </el-row>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">商标状态分析图</p>
        <el-row style="background-color: #F3F7FE;">
          <p class="analysisContentTitle2">商标状态统计</p>
          <div class="analysisContentChart middleLine" id="applicantTrademarkStatusVolumn"/>
        </el-row>

        <el-row style="margin-top: 10px; background-color: #F3F7FE;" class="middleLine2">
          <el-col :span="12">
            <p class="analysisContentTitle2">注册通过率</p>
            <el-progress
              class="agentRegistrationPassRate"
              type="dashboard"
              :percentage="applicantRegistrationPassRate"
              color="#F5BA18"
              :stroke-width="12"
              stroke-linecap="square"
              style="white-space: pre"
              :format="getProgressText"
            />
          </el-col>
          <el-col :span="12">
            <p class="analysisContentTitle2">申请驳回率</p>
            <el-progress
              class="agentRejectionRate"
              type="dashboard"
              :percentage="applicantRejectionRate"
              color="#0052D9"
              :stroke-width="12"
              stroke-linecap="square"
              style="white-space: pre"
              :format="getProgressText"
            />
          </el-col>
          <p class="myNote2">注：部分审查中的数据会影响注册通过率，该数据仅供参考</p>
        </el-row>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">本地区商标代理机构TOP10</p>
        <el-row style="border-radius: 5px; overflow: hidden">
          <vxe-table
            :data = applicantAreaAgentData
            :height="heightFlag ? '' : 300 "
            size="small"
            :tooltip-config="{ zIndex: 9999 }"
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
          >
            <vxe-column
              type="seq"
              width="50"
              title="序号"
            />
            <vxe-column
              align="center"
              field="agentName"
              width="150"
              title="代理机构名称"
              show-overflow
            />
            <vxe-column
              align="center"
              field="agentCount"
              title="重点行业总代理量"
              show-overflow
            />
            <vxe-column
              align="center"
              field="agentAddress"
              title="地址"
              show-overflow
            />
          </vxe-table>
        </el-row>
      </div>

      <div class="analysisContentItem" v-show="queryData && queryData.applicantForeignYearDtoList!=null && queryData.applicantForeignYearDtoList.length > 0">
        <p class="analysisContentTitle">海外商标申请趋势</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="applicantForeignAnnualTrend"/>
        </div>
      </div>

      <div class="analysisContentItem" v-show="queryData && queryData.applicantCountryDtoList!=null && queryData.applicantCountryDtoList.length > 0">
        <p class="analysisContentTitle">海外各国的商标申请数量</p>

        <el-row style="border-radius: 5px; overflow: hidden">
          <el-table
            :data = applicantForeignCountryTableData
            :height="heightFlag ? '' : 220 "
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
            :cell-style = "{'padding':'5px 0'}"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
            />
            <el-table-column
              align="center"
              prop="country"
              label="国家/地区"
            />
            <el-table-column
              align="center"
              prop="appCount"
              label="数量"
            />
          </el-table>
        </el-row>

        <p class="analysisContentTitle">海外各国商标数量统计</p>

        <el-row>
          <div class="analysisContentChart" id="applicantForeignCountryStatistic"/>
        </el-row>
      </div>

      <div class="analysisContentItem" v-show="queryData && queryData.applicantForeignNiceDtoList!=null && queryData.applicantForeignNiceDtoList.length > 0">
        <p class="analysisContentTitle">海外重点行业布局</p>

        <el-row style="border-radius: 5px; overflow: hidden">
          <el-table
            :data = applicantForeignBussinessTableData
            :height="heightFlag ? '' : 220 "
            :header-cell-style = "{'padding':'10px 0', 'font-weight':'bolder', 'color':'#030302', 'font-size':'14px','background-color':'#E2EFFF'}"
            :cell-style = "{'padding':'5px 0'}"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
            />
            <el-table-column
              align="center"
              prop="nice"
              label="类别"
            />
            <el-table-column
              align="center"
              prop="appCount"
              label="商标数量"
            />
            <el-table-column
              align="center"
              prop="niceName"
              label="服务名称"
            />
          </el-table>
        </el-row>

        <p class="analysisContentTitle">海外行业统计</p>
        <el-row>
          <div class="analysisContentChart" id="applicantForeignBussinessStatistic"/>
        </el-row>
      </div>

      <p class="myNote">免责声明：本报告中的数据信息并非实时更新，存在一定滞后性，仅供参考，不具有法律效力</p>
    </div>
    <div id="agentAnalysis" v-if="reportType == 1">
      <h1 class="analysisTitle">【{{queryKeyword}}】商标代理分析报告</h1>

      <div class="analysisContentItem" style="padding-bottom: 0">
        <p class="analysisContentTitle">商标代理总量</p>
        <div class="trademarkAgentTotal" style="border-radius: 5px; overflow: hidden">
          <svg width="358" height="80" viewBox="0 0 358 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 895">
              <g id="Group 894">
                <rect id="Rectangle 1266" width="358" height="80" rx="5" fill="url(#paint0_linear_602_906)"/>
                <g id="Mask group">
                  <mask id="mask0_602_906" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="358" height="80">
                    <rect id="Rectangle 1264" width="358" height="80" rx="5" fill="#CBD6FD"/>
                  </mask>
                  <g mask="url(#mask0_602_906)">
                    <path id="Rectangle 1269" d="M8.60742 -164.277L8.6074 164.971L-32.3575 164.971L-32.3575 -164.277L8.60742 -164.277Z" fill="#0F50BB"/>
                  </g>
                </g>
              </g>
              <rect id="Rectangle 1268" x="278" y="21" width="47" height="42" rx="8" fill="#BBC9FC"/>
              <g id="&#231;&#148;&#179;&#232;&#175;&#183; 2">
                <path id="Vector" d="M306.812 41.3438L304.156 38.6875L310.719 32.125H291.5C290.406 32.125 289.625 32.9063 289.625 34V49C289.625 50.0938 290.406 50.875 291.5 50.875H311.5C312.594 50.875 313.375 50.0938 313.375 49V34.7813L306.812 41.3438ZM303.531 39.4687C303.531 39.1562 303.844 39.1562 303.844 39.4687L306.031 41.5V41.8125L303.375 42.125C303.375 42.125 303.063 42.125 303.063 41.8125L303.531 39.4687ZM293.062 35.5625H298.844C299.313 35.5625 299.625 35.875 299.625 36.3437C299.625 36.8125 299.313 37.125 298.844 37.125H293.062C292.594 37.125 292.281 36.8125 292.281 36.3437C292.281 36.0312 292.906 35.5625 293.062 35.5625ZM293.062 40.5625H298.844C299.313 40.5625 299.625 40.875 299.625 41.3438C299.625 41.8125 299.313 42.125 298.844 42.125H293.062C292.75 42.125 292.281 41.8125 292.281 41.3438C292.281 40.875 292.906 40.5625 293.062 40.5625ZM309.156 47.2812H293.062C292.594 47.2812 292.281 46.9687 292.281 46.5C292.281 46.0312 292.594 45.7187 293.062 45.7187H309.156C309.625 45.7187 309.938 46.0312 309.938 46.5C309.938 47.125 309.625 47.2812 309.156 47.2812Z" fill="white"/>
              </g>
            </g>
            <defs>
              <linearGradient id="paint0_linear_602_906" x1="-14.5" y1="40.5063" x2="358" y2="40.0126" gradientUnits="userSpaceOnUse">
                <stop stop-color="#123694"/>
                <stop offset="0.125673" stop-color="#CDD9F9" stop-opacity="0.8"/>
                <stop offset="1" stop-color="#FBFDFF"/>
              </linearGradient>
            </defs>
          </svg>
          <span>{{ agentTotal }}</span>
        </div>
        <p style="font-size: 12px; margin: 10px 0 0 0; color: #666666">
          注:统计数据截止到2024年上半年
        </p>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">年度代理趋势</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="agentAnnualApplicationTrend"/>
        </div>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">中国大陆代理量TOP5地区的TOP5申请人（34个省市自治区）</p>
        <div class="analysisContentTable" style="border-radius: 5px; overflow: hidden">
          <table :class="[{'active': !heightFlag}]">
            <tr>
              <th>地区</th>
              <th>地区总量</th>
              <th>申请人名称</th>
              <th>代理量</th>
            </tr>
            <tr v-for="item in analysisContentTableData">
              <td v-if="item.area != ''" style="border-right: none">{{item.area}}</td>
              <td v-else style="border: none; background-color: #F4F5F7"/>
              <td v-if="item.area != ''" style="border-left: none">{{item.total}}</td>
              <td v-else style="border: none; background-color: #F4F5F7"/>
              <td v-if="item.area != ''">{{item.applicantName}}</td>
              <td v-else>{{item.applicantName}}</td>
              <td v-if="item.area != ''">{{item.count}}</td>
              <td v-else>{{item.count}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">地区代理量统计</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="agentRegionalAgencyVolumn"/>
        </div>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">商标代理-商标状态分析</p>
        <el-row style="background-color: #F3F7FE;">
          <p class="analysisContentTitle2">商标状态统计</p>
          <div class="analysisContentChart middleLine" id="agentTrademarkStatusVolumn"/>
        </el-row>

        <el-row style="margin-top: 10px; background-color: #F3F7FE;" class="middleLine2">
          <el-col :span="12">
            <p class="analysisContentTitle2">注册通过率</p>
            <el-progress
              class="agentRegistrationPassRate"
              type="dashboard"
              :percentage="agentRegistrationPassRate"
              color="#F5BA18"
              :stroke-width="12"
              stroke-linecap="square"
              style="white-space: pre"
              :format="getProgressText"
            />
          </el-col>
          <el-col :span="12">
            <p class="analysisContentTitle2">申请驳回率</p>
            <el-progress
              class="agentRejectionRate"
              type="dashboard"
              :percentage="agentRejectionRate"
              color="#0052D9"
              :stroke-width="12"
              stroke-linecap="square"
              style="white-space: pre"
              :format="getProgressText"
            />
          </el-col>
          <p class="myNote2">注：部分审查中的数据会影响注册通过率，该数据仅供参考</p>
        </el-row>
      </div>

      <div class="analysisContentItem">
        <p class="analysisContentTitle">代理商品类别Top10</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="agentCategory"/>
        </div>
      </div>

      <div class="analysisContentItem" v-show="queryData && queryData.tmAgentForeign10yearsDOList!=null && queryData.tmAgentForeign10yearsDOList.length > 0">
        <p class="analysisContentTitle">海外商标代理量趋势</p>
        <div style="border-radius: 5px; overflow: hidden">
          <div class="analysisContentChart" id="agentAnnualApplicationTrendForeign"/>
        </div>
      </div>

      <div class="analysisContentItem" v-show="queryData && queryData.tmAgentCountryDOList!=null && queryData.tmAgentCountryDOList.length > 0">
        <p class="analysisContentTitle">海外商标代理量TOP5国家/地区的TOP5申请人</p>

        <el-row class="analysisContentTable" style="border-radius: 5px; overflow: hidden">
          <table :class="[{'active': !heightFlag}]">
            <tr>
              <th>地区</th>
              <th>地区总量</th>
              <th>申请人名称</th>
              <th>代理量</th>
            </tr>
            <tr v-for="item in analysisContentTableDataForeign">
              <td v-if="item.country != ''" style="border-right: none">{{item.country}}</td>
              <td v-else style="border: none; background-color: #F4F5F7"/>
              <td v-if="item.country != ''" style="border-left: none">{{item.total}}</td>
              <td v-else style="border: none; background-color: #F4F5F7"/>
              <td v-if="item.country != ''">{{item.applicantName}}</td>
              <td v-else>{{item.applicantName}}</td>
              <td v-if="item.country != ''">{{item.count}}</td>
              <td v-else>{{item.count}}</td>
            </tr>
          </table>
        </el-row>

        <p class="analysisContentTitle">海外代理量统计</p>
        <el-row>
          <div class="analysisContentChart" id="agentRegionalForeign"/>
        </el-row>
      </div>

      <p class="myNote">免责声明：本报告中的数据信息并非实时更新，存在一定滞后性，仅供参考，不具有法律效力</p>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: "analysisReport",
  props: {
    //0-申请人报告 1-代理机构报告
    reportType:{
      type: Number,
      required: false,
      default: 1
    },
    queryKeyword:{
      type: String,
      required: false,
      default: '北京集佳知识产权代理有限公司'
    },
    queryData:{
      type: Object,
      required: false,
      default: null
    }
  },
  data:()=>{
    return{
      heightFlag: false,
      //0-申请人报告 1-代理机构报告
      // reportType:0,
      // queryKeyword:"北京集佳知识产权代理有限公司",
      // queryKeyword:"腾讯科技（深圳）有限公司",

      appCount:0,
      applicantAnnualApplicationTrendData:[],
      applicantKeyTrademarkTableData:[],
      applicantKeyBussinessData:[],
      applicantAgentData:[],
      applicantTrademarkStatusVolumnData:[],
      applicantRegistrationPassRate:0,
      applicantRejectionRate:0,
      applicantAreaAgentData:[],
      applicantForeignAnnualTrendData:[],
      applicantForeignCountryTableData:[],
      applicantForeignBussinessTableData:[],

      agentTotal:0,
      agentAnnualApplicationTrendData:[],
      analysisContentTableData:[],
      agentRegionalAgencyVolumnData:[],
      agentTrademarkStatusVolumnData:[],
      agentRegistrationPassRate:0,
      agentRejectionRate:0,
      agentCategoryData:[],
      agentAnnualApplicationTrendDataForeign:[],
      analysisContentTableDataForeign:[],
      agentRegionalAgencyVolumnDataForeign:[],
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      if (this.reportType == 1){
        //代理机构年度申请趋势
        echarts.init(document.getElementById("agentAnnualApplicationTrend"))
        //代理机构地区代理量统计
        echarts.init(document.getElementById("agentRegionalAgencyVolumn"))
        //代理机构商标状态统计
        echarts.init(document.getElementById("agentTrademarkStatusVolumn"))
        //代理机构代理商品类别Top10
        echarts.init(document.getElementById("agentCategory"))
        //代理机构海外商标代理量趋势
        echarts.init(document.getElementById("agentAnnualApplicationTrendForeign"))
        //代理机构海外地区代理量
        echarts.init(document.getElementById("agentRegionalForeign"))

        // let queryParam = {
        //   agent:this.queryKeyword
        // }
        // this.$axios.post('/common/query/agent/analysis', queryParam).then(response=>{
        //   let data = response.data

          let data = this.queryData

          this.agentTotal = data.appCount

          this.agentAnnualApplicationTrendData = data.tmAgent10yearsDOList

          this.analysisContentTableData = []
          let areaData = []
          for (let i = 0; i < (5 > data.tmAgentAreaDOList.length ? data.tmAgentAreaDOList.length : 5); i++){
            areaData.push(data.tmAgentAreaDOList[i])
          }
          let nowArea = ''
          areaData.forEach(item=>{
            item.applicantVOList.forEach(it=>{
              this.analysisContentTableData.push({
                area:item.area != nowArea ? item.area : '',
                total:item.agentCount,
                applicantName:it.applicantName,
                count:it.appCount
              })
              nowArea = item.area != nowArea ? item.area : nowArea
            })
          })
          this.agentRegionalAgencyVolumnData = areaData

          this.agentTrademarkStatusVolumnData = data.tmAgentStatusDOList

          if(Number(data.regRate.split("%")[0]) >= 0 && Number(data.regRate.split("%")[0]) <= 100){
            this.agentRegistrationPassRate = data.regRate == null ? 0 : Number(data.regRate.split("%")[0])
          }
          else {
            this.$message.error("注册通过率错误")
            this.agentRegistrationPassRate = 0
          }

          if (Number(data.rejectRate.split("%")[0]) >= 0 && Number(data.rejectRate.split("%")[0]) <= 100){
            this.agentRejectionRate = data.rejectRate == null ? 0 : Number(data.rejectRate.split("%")[0])
          }
          else {
            this.$message.error("申请驳回率错误")
            this.agentRejectionRate = 0
          }

          this.agentCategoryData = []
          data.tmAgentNiceDOList.forEach(item=>{
            let arr = []
            arr.push(Number(item.nice))
            arr.push(Number(item.agentCount))
            this.agentCategoryData.push(arr)
          })

          this.agentAnnualApplicationTrendDataForeign = data.
        tmAgentForeign10yearsDOList
          this.analysisContentTableDataForeign = []
          let areaDataForeign = []
          for (let i = 0; i < (5 > data.tmAgentCountryDOList.length ? data.tmAgentCountryDOList.length : 5); i++){
            areaDataForeign.push(data.tmAgentCountryDOList[i])
          }
          let nowAreaForeign = ''
          areaDataForeign.forEach(item=>{
            item.applicantVOList.forEach(it=>{
              this.analysisContentTableDataForeign.push({
                country:item.country != nowAreaForeign ? item.country : '',
                total:item.agentCount,
                applicantName:it.applicantName,
                count:it.appCount
              })
              nowAreaForeign = item.country != nowAreaForeign ? item.country : nowAreaForeign
            })
          })
          this.agentRegionalAgencyVolumnDataForeign = areaDataForeign

          let agentAnnualApplicationTrend = require('echarts').getInstanceByDom(document.getElementById("agentAnnualApplicationTrend"))
          let agentAnnualApplicationTrendConfig = this.getAgentAnnualApplicationTrendConfig()
          agentAnnualApplicationTrend.clear()
          agentAnnualApplicationTrend.setOption(agentAnnualApplicationTrendConfig, true)

          let agentRegionalAgencyVolumn = require('echarts').getInstanceByDom(document.getElementById("agentRegionalAgencyVolumn"))
          let agentRegionalAgencyVolumnConfig = this.getAgentRegionalAgencyVolumnConfig()
          agentRegionalAgencyVolumn.clear()
          agentRegionalAgencyVolumn.setOption(agentRegionalAgencyVolumnConfig, true)

          let agentTrademarkStatusVolumn = require('echarts').getInstanceByDom(document.getElementById("agentTrademarkStatusVolumn"))
          let agentTrademarkStatusVolumnConfig = this.getAgentTrademarkStatusVolumnConfig()
          agentTrademarkStatusVolumn.clear()
          agentTrademarkStatusVolumn.setOption(agentTrademarkStatusVolumnConfig, true)

          let agentCategory = require('echarts').getInstanceByDom(document.getElementById("agentCategory"))
          let agentCategoryConfig = this.getAgentCategoryConfig()
          agentCategory.clear()
          agentCategory.setOption(agentCategoryConfig, true)

          let agentAnnualApplicationTrendForeign = require('echarts').getInstanceByDom(document.getElementById("agentAnnualApplicationTrendForeign"))
          let agentAnnualApplicationTrendForeignConfig = this.getAgentAnnualApplicationTrendForeignConfig()
          agentAnnualApplicationTrendForeign.clear()
          agentAnnualApplicationTrendForeign.setOption(agentAnnualApplicationTrendForeignConfig, true)

          let agentRegionalForeign = require('echarts').getInstanceByDom(document.getElementById("agentRegionalForeign"))
          let agentRegionalForeignConfig = this.getAgentRegionalForeignConfig()
          agentRegionalForeign.clear()
          agentRegionalForeign.setOption(agentRegionalForeignConfig, true)
        // })
      }
      else {
        //中国大陆申请量趋势图
        echarts.init(document.getElementById("applicantAnnualApplicationTrend"))
        //重点商标统计
        echarts.init(document.getElementById("applicantKeyTrademarkStatistic"))
        //重点行业统计
        echarts.init(document.getElementById("applicantKeyBussinessStatistic"))
        //代理机构统计
        echarts.init(document.getElementById("applicantAgentStatistic"))
        //商标状态统计
        echarts.init(document.getElementById("applicantTrademarkStatusVolumn"))
        //海外商标申请趋势
        echarts.init(document.getElementById("applicantForeignAnnualTrend"))
        //海外各国的商标申请数量
        echarts.init(document.getElementById("applicantForeignCountryStatistic"))
        //海外重点行业布局
        echarts.init(document.getElementById("applicantForeignBussinessStatistic"))

        // let queryParam = {
        //   applicant:this.queryKeyword
        // }
        // this.$axios.post('/common/query/applicant/analysis', queryParam).then(response=>{
        //   let data = response.data

          let data = this.queryData

          this.appCount = data.appCount
          this.applicantAnnualApplicationTrendData = data.applicantYearDtoList
          this.applicantKeyTrademarkTableData = data.applicantKeyTmDtoList
          this.applicantKeyBussinessData = data.applicantNiceDtoList
          this.applicantAgentData = data.applicantAgentDtoList
          this.applicantTrademarkStatusVolumnData = data.applicantStatusDtoList
          if(Number(data.regRate.split("%")[0]) >= 0 && Number(data.regRate.split("%")[0]) <= 100){
            this.applicantRegistrationPassRate = data.regRate == null ? 0 : Number(data.regRate.split("%")[0])
          }
          else {
            this.$message.error("注册通过率错误")
            this.applicantRegistrationPassRate = 0
          }
          if(Number(data.rejectRate.split("%")[0]) >= 0 && Number(data.rejectRate.split("%")[0]) <= 100){
            this.applicantRejectionRate = data.rejectRate == null ? 0 : Number(data.rejectRate.split("%")[0])
          }
          else {
            this.$message.error("申请驳回率错误")
            this.applicantRejectionRate = 0
          }
          this.applicantAreaAgentData = data.applicantAreaDtoList
          this.applicantForeignAnnualTrendData = data.applicantForeignYearDtoList
          this.applicantForeignCountryTableData = data.applicantCountryDtoList
          this.applicantForeignBussinessTableData = data.applicantForeignNiceDtoList

          let applicantAnnualApplicationTrend = require('echarts').getInstanceByDom(document.getElementById("applicantAnnualApplicationTrend"))
          let applicantAnnualApplicationTrendConfig = this.getApplicantAnnualApplicationTrendConfig()
          applicantAnnualApplicationTrend.clear()
          applicantAnnualApplicationTrend.setOption(applicantAnnualApplicationTrendConfig, true)

          let applicantKeyTrademarkStatistic = require('echarts').getInstanceByDom(document.getElementById("applicantKeyTrademarkStatistic"))
          let applicantKeyTrademarkStatisticConfig = this.getApplicantKeyTrademarkStatisticConfig()
          applicantKeyTrademarkStatistic.clear()
          applicantKeyTrademarkStatistic.setOption(applicantKeyTrademarkStatisticConfig, true)

          let applicantKeyBussinessStatistic = require('echarts').getInstanceByDom(document.getElementById("applicantKeyBussinessStatistic"))
          let applicantKeyBussinessStatisticConfig = this.getApplicantKeyBussinessStatisticConfig()
          applicantKeyBussinessStatistic.clear()
          applicantKeyBussinessStatistic.setOption(applicantKeyBussinessStatisticConfig, true)

          let applicantAgentStatistic = require('echarts').getInstanceByDom(document.getElementById("applicantAgentStatistic"))
          let applicantAgentStatisticConfig = this.getApplicantAgentStatisticConfig()
          applicantAgentStatistic.clear()
          applicantAgentStatistic.setOption(applicantAgentStatisticConfig, true)

          let applicantTrademarkStatusVolumn = require('echarts').getInstanceByDom(document.getElementById("applicantTrademarkStatusVolumn"))
          let applicantTrademarkStatusVolumnConfig = this.getApplicantTrademarkStatusVolumnConfig()
          applicantTrademarkStatusVolumn.clear()
          applicantTrademarkStatusVolumn.setOption(applicantTrademarkStatusVolumnConfig, true)

          let applicantForeignAnnualTrend = require('echarts').getInstanceByDom(document.getElementById("applicantForeignAnnualTrend"))
          let applicantForeignAnnualTrendConfig = this.getApplicantForeignAnnualTrendConfig()
          applicantForeignAnnualTrend.clear()
          applicantForeignAnnualTrend.setOption(applicantForeignAnnualTrendConfig, true)

          let applicantForeignCountryStatistic = require('echarts').getInstanceByDom(document.getElementById("applicantForeignCountryStatistic"))
          let applicantForeignCountryStatisticConfig = this.getApplicantForeignCountryStatisticConfig()
          applicantForeignCountryStatistic.clear()
          applicantForeignCountryStatistic.setOption(applicantForeignCountryStatisticConfig, true)

          let applicantForeignBussinessStatistic = require('echarts').getInstanceByDom(document.getElementById("applicantForeignBussinessStatistic"))
          let applicantForeignBussinessStatisticConfig = this.getApplicantForeignBussinessStatisticConfig()
          applicantForeignBussinessStatistic.clear()
          applicantForeignBussinessStatistic.setOption(applicantForeignBussinessStatisticConfig, true)
        // })
      }
    },
    downloadAnalysis(){
      switch (this.reportType){
        case 0:
          this.heightFlag = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.getPdf("applicantAnalysis", "【"+this.queryKeyword+"】分析报告");
            })
          })
          break;
        case 1:
          this.heightFlag = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.getPdf("agentAnalysis", "【"+this.queryKeyword+"】分析报告");
            })
          })
          break
        default:
          break
      }
      this.$EventBus.$on('pdf', ()=> {
        console.log(' this.heightFlag = true; this.heightFlag = true;')
        this.heightFlag = false;
      })
    },
    getProgressText(percentage){
      return percentage+"%\n"
    },

    //申请人分析报告
    getApplicantAnnualApplicationTrendConfig(){
      let year = []
      let count = []
      let year2AppCount = new Map()
      this.applicantAnnualApplicationTrendData.forEach(item=>{
        year2AppCount.set(item.year, item.appCount)
      })
      let startYear = new Date().getFullYear() - 10
      for(let i = 0; i < 10; i++){
        if (year2AppCount.has(startYear)){
          year.push(startYear)
          count.push(year2AppCount.get(startYear))
          startYear++
        }
        else {
          year.push(startYear)
          count.push(0)
          startYear++
        }
      }

      return {
        backgroundColor:'#ffffff',
        xAxis: {
          type: 'category',
          data: year,
          axisLabel:{
            interval:0,
          },
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          },
        },
        series: {
          data: count,
          type: 'line',
          symbol: 'none',
          smooth:true,
          lineStyle:{
            color: '#466CF4',
          },
          areaStyle: {
            color: '#E0EBFE',
          },
          emphasis: {
            focus:'series'
          }
        },
        emphasis: {
          areaStyle: {
            color: '#E0EBFE',
          },
        },
        tooltip: {
          show:true,
          trigger: 'axis',
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getApplicantKeyTrademarkStatisticConfig(){
      let color = ["#0052D9", "#029CD4",  "#2BA471", "#F5BA18", "#E37318", "#E851B3", "#8E56DD", "#D54941"]
      let data = []
      let legend = []
      this.applicantKeyTrademarkTableData.forEach(item=>{
        data.push({
          name:item.tmName,
          value:item.niceSet.length + 45
        })
        legend.push(item.tmName)
      })

      return {
        tooltip: {
          trigger: 'item',
          formatter:function (item) {
            let realValue = item.value - 45
            return item.name + ": " + realValue
          }
        },
        color:color,
        series:{
          type: 'pie',
          roseType:'area',
          center:['20%', '50%'],
          label: { show: false },
          data: data,
        },
        legend: {
          type: 'plain',
          orient: 'vertical',
          show: true,
          right: "10",
          top: "40",
          data: legend,
          itemGap:20,
          icon:'circle',
          textStyle: {
            rich: {
              count:{
                color:"#6285E5"
              }
            }
          },
          formatter:function (item){
            if (item.length > 10){
              return item.slice(0, 10)+"..."
            }
            return item
          }
        }
      }
    },
    getApplicantKeyBussinessStatisticConfig(){
      let label = []
      let data = []
      this.applicantKeyBussinessData.forEach(item=>{
        label.push(item.nice+"类")
        data.push(item.appCount)
      })

      return {
        backgroundColor:' #ffffff',
        xAxis: {
          type: 'category',
          data: label,
          axisLabel:{
            interval:0,
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
        },
        series: {
          data: data,
          type: 'bar',
          color:'#0052D9',
          barWidth:'30%'
        },
        tooltip: {
          show:true,
          trigger: 'axis',
          axisPointer:{
            type:'shadow',
            shadowStyle:{
              shadowColor: '#E8E8E8',
            }
          },
          formatter:function (item){
            let title = ""
            switch (item[0].axisValue){
              case '01类':
                title="01类 工业用化学品"
                break
              case '02类':
                title="02类 颜料油漆树脂"
                break
              case '03类':
                title="03类 清洁化妆用品"
                break
              case '04类':
                title="04类 油脂燃料能源"
                break
              case '05类':
                title="05类 医药卫生用品"
                break
              case '06类':
                title="06类 金属五金器具"
                break
              case '07类':
                title="07类 机器机械设备"
                break
              case '08类':
                title="08类 餐具手工器具"
                break
              case '09类':
                title="09类 科学电子设备"
                break
              case '10类':
                title="10类 医疗护理器械"
                break
              case '11类':
                title="11类 照明空调卫浴"
                break
              case '12类':
                title="12类 交通运输工具"
                break
              case '13类':
                title="13类 军火烟火爆竹"
                break
              case '14类':
                title="14类 珠宝首饰钟表"
                break
              case '15类':
                title="15类 乐器及其配件"
                break
              case '16类':
                title="16类 办公教学用品"
                break
              case '17类':
                title="17类 塑料橡胶制品"
                break
              case '18类':
                title="18类 箱包皮具皮革"
                break
              case '19类':
                title="19类 非金属的建材"
                break
              case '20类':
                title="20类 家具容器骨石"
                break
              case '21类':
                title="21类 家居厨房器具"
                break
              case '22类':
                title="22类 绳网帐篷帆布"
                break
              case '23类':
                title="23类 纺织用纱和线"
                break
              case '24类':
                title="24类 日常用纺织品"
                break
              case '25类':
                title="25类 服装服饰鞋帽"
                break
              case '26类':
                title="26类 花边配饰假发"
                break
              case '27类':
                title="27类 地毯席类墙纸"
                break
              case '28类':
                title="28类 游戏运动器具"
                break
              case '29类':
                title="29类 蔬果肉蛋奶油"
                break
              case '30类':
                title="30类 咖啡面点调料"
                break
              case '31类':
                title="31类 水果蔬菜花卉"
                break
              case '32类':
                title="32类 啤酒饮料糖浆"
                break
              case '33类':
                title="33类 含酒精的饮料"
                break
              case '34类':
                title="34类 烟草烟具火柴"
                break
              case '35类':
                title="35类 广告办公销售"
                break
              case '36类':
                title="36类 保险金融服务"
                break
              case '37类':
                title="37类 建筑修理安装"
                break
              case '38类':
                title="38类 电信通信服务"
                break
              case '39类':
                title="39类 运输仓储服务"
                break
              case '40类':
                title="40类 材料处理服务"
                break
              case '41类':
                title="41类 教育娱乐文体"
                break
              case '42类':
                title="42类 设计咨询开发"
                break
              case '43类':
                title="43类 饮食住宿看护"
                break
              case '44类':
                title="44类 医疗美容园林"
                break
              case '45类':
                title="45类 法律安全服务"
                break
            }
            return title + "    "+item[0].data
          }
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getApplicantAgentStatisticConfig(){
      let color = ["#0052D9", "#2BA471",  "#E37318", "#D54941", "#D54941", "#029CD4", "#F5BA18"]
      let myDate = [{name:'商标总量'}]
      let mylink = []
      let agentCountTotal = 0
      this.applicantAgentData.forEach(item=>{
        myDate.push({
          name: item.agentName
        })
        mylink.push({
          source: '商标总量',
          target: item.agentName,
          value: item.appSelfCount
        })
        agentCountTotal += item.appSelfCount
      })

      if (agentCountTotal < this.appCount){
        let temp = this.appCount - agentCountTotal
        myDate.push({
          name:'其他代理机构'
        })
        mylink.push({
          source: '商标总量',
          target: '其他代理机构',
          value: temp
        })
      }

      return {
        tooltip: {
          trigger: 'item'
        },
        series: {
          type: 'sankey',
          layout: 'none',
          color:color,
          emphasis: {
            focus: 'adjacency'
          },
          data: myDate,
          links: mylink,
          lineStyle: {
            //217,229,249
            color:'#0052D9'
          },
        }
      }
    },
    getApplicantTrademarkStatusVolumnConfig(){
      let that = this
      let legend1 = ['已注册','无效','注册申请审查中','驳回复审审查中', '撤销/无效宣告申请审查中']
      let legend2 = ['公告中','异议审查中']
      let color = ["#0052D9", "#2BA471",  "#E37318", "#D54941", "#D54941", "#029CD4", "#F5BA18"]
      let data = []

      this.applicantTrademarkStatusVolumnData.forEach(item=>{
        data.push({
          name:item.status,
          value:item.appCount
        })
      })

      return {
        tooltip: {
          trigger: 'item'
        },
        color:color,
        series:{
          type: 'pie',
          radius: ['25%', '40%'],
          center:['20%', '50%'],
          label: { show: false, position: 'center' },
          data: data,
          emphasis: {
            label:{
              formatter:`{c}`,
              show: true,
              fontsize: 14,
              color: '#6285E5'
            }
          },
          itemStyle:{
            borderWidth:1,
            borderColor:'rgb(255,255,255)'
          }
        },
        legend:[
          {
            type: 'plain',
            orient: 'vertical',
            show: true,
            right: "90",
            top: "40",
            data: legend1,
            itemGap:20,
            icon:'circle',
            formatter:function (item){
              let count = 0
              that.applicantTrademarkStatusVolumnData.forEach(it=>{
                if (it.status == item){
                  count = it.appCount
                }
              })
              var arr = [
                '{name|'+item+"}",
                '{count|'+count+"}"
              ]
              return arr.join('    ')
            },
            textStyle: {
              rich: {
                count:{
                  color:"#6285E5"
                }
              }
            }
          },
          {
            type: 'plain',
            orient: 'vertical',
            show: true,
            right: "right",
            top:"40",
            data: legend2,
            itemGap:20,
            icon:'circle',
            formatter:function (item){
              let count = 0
              that.applicantTrademarkStatusVolumnData.forEach(it=>{
                if (it.status == item){
                  count = it.appCount
                }
              })
              var arr = [
                '{name|'+item+"}",
                '{count|'+count+"}"
              ]
              return arr.join('    ')
            },
            textStyle: {
              rich: {
                count:{
                  color:"#6285E5"
                }
              }
            }
          }
        ]
      }
    },
    getApplicantForeignAnnualTrendConfig(){
      let year = []
      let count = []
      let year2AppCount = new Map()
      this.applicantForeignAnnualTrendData.forEach(item=>{
        year2AppCount.set(item.year, item.appCount)
      })
      let startYear = new Date().getFullYear() - 10
      for(let i = 0; i < 10; i++){
        if (year2AppCount.has(startYear)){
          year.push(startYear)
          count.push(year2AppCount.get(startYear))
          startYear++
        }
        else {
          year.push(startYear)
          count.push(0)
          startYear++
        }
      }

      return {
        backgroundColor:'#ffffff',
        xAxis: {
          type: 'category',
          data: year,
          axisLabel:{
            interval:0,
          },
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          },
        },
        series: {
          data: count,
          type: 'line',
          symbol: 'none',
          smooth:true,
          lineStyle:{
            color: '#466CF4',
          },
          areaStyle: {
            color: '#E0EBFE',
          },
          emphasis: {
            focus:'series'
          }
        },
        emphasis: {
          areaStyle: {
            color: '#E0EBFE',
          },
        },
        tooltip: {
          show:true,
          trigger: 'axis',
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getApplicantForeignCountryStatisticConfig(){
      let color = ["#0052D9", "#2BA471",  "#E37318", "#D54941", "#D54941", "#029CD4", "#F5BA18"]
      let myDate = [{name:'海外总数量'}]
      let mylink = []

      this.applicantForeignCountryTableData.forEach(item=>{
        myDate.push({
          name: item.country
        })
        mylink.push({
          source: '海外总数量',
          target: item.country,
          value: item.appCount
        })
      })

      return {
        tooltip: {
          trigger: 'item'
        },
        series: {
          type: 'sankey',
          layout: 'none',
          color:color,
          emphasis: {
            focus: 'adjacency'
          },
          data: myDate,
          links: mylink,
          lineStyle: {
            //217,229,249
            color:'#0052D9'
          },
        }
      }
    },
    getApplicantForeignBussinessStatisticConfig(){
      let label = []
      let data = []
      this.applicantForeignBussinessTableData.forEach(item=>{
        label.push(item.nice+"类")
        data.push(item.appCount)
      })

      return {
        backgroundColor:' #ffffff',
        xAxis: {
          type: 'category',
          data: label,
          axisLabel:{
            interval:0,
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
        },
        series: {
          data: data,
          type: 'bar',
          color:'#0052D9',
          barWidth:'30%'
        },
        tooltip: {
          show:true,
          trigger: 'axis',
          axisPointer:{
            type:'shadow',
            shadowStyle:{
              shadowColor: '#E8E8E8',
            }
          },
          formatter:function (item){
            let title = ""
            switch (item[0].axisValue){
              case '01类':
                title="01类 工业用化学品"
                break
              case '02类':
                title="02类 颜料油漆树脂"
                break
              case '03类':
                title="03类 清洁化妆用品"
                break
              case '04类':
                title="04类 油脂燃料能源"
                break
              case '05类':
                title="05类 医药卫生用品"
                break
              case '06类':
                title="06类 金属五金器具"
                break
              case '07类':
                title="07类 机器机械设备"
                break
              case '08类':
                title="08类 餐具手工器具"
                break
              case '09类':
                title="09类 科学电子设备"
                break
              case '10类':
                title="10类 医疗护理器械"
                break
              case '11类':
                title="11类 照明空调卫浴"
                break
              case '12类':
                title="12类 交通运输工具"
                break
              case '13类':
                title="13类 军火烟火爆竹"
                break
              case '14类':
                title="14类 珠宝首饰钟表"
                break
              case '15类':
                title="15类 乐器及其配件"
                break
              case '16类':
                title="16类 办公教学用品"
                break
              case '17类':
                title="17类 塑料橡胶制品"
                break
              case '18类':
                title="18类 箱包皮具皮革"
                break
              case '19类':
                title="19类 非金属的建材"
                break
              case '20类':
                title="20类 家具容器骨石"
                break
              case '21类':
                title="21类 家居厨房器具"
                break
              case '22类':
                title="22类 绳网帐篷帆布"
                break
              case '23类':
                title="23类 纺织用纱和线"
                break
              case '24类':
                title="24类 日常用纺织品"
                break
              case '25类':
                title="25类 服装服饰鞋帽"
                break
              case '26类':
                title="26类 花边配饰假发"
                break
              case '27类':
                title="27类 地毯席类墙纸"
                break
              case '28类':
                title="28类 游戏运动器具"
                break
              case '29类':
                title="29类 蔬果肉蛋奶油"
                break
              case '30类':
                title="30类 咖啡面点调料"
                break
              case '31类':
                title="31类 水果蔬菜花卉"
                break
              case '32类':
                title="32类 啤酒饮料糖浆"
                break
              case '33类':
                title="33类 含酒精的饮料"
                break
              case '34类':
                title="34类 烟草烟具火柴"
                break
              case '35类':
                title="35类 广告办公销售"
                break
              case '36类':
                title="36类 保险金融服务"
                break
              case '37类':
                title="37类 建筑修理安装"
                break
              case '38类':
                title="38类 电信通信服务"
                break
              case '39类':
                title="39类 运输仓储服务"
                break
              case '40类':
                title="40类 材料处理服务"
                break
              case '41类':
                title="41类 教育娱乐文体"
                break
              case '42类':
                title="42类 设计咨询开发"
                break
              case '43类':
                title="43类 饮食住宿看护"
                break
              case '44类':
                title="44类 医疗美容园林"
                break
              case '45类':
                title="45类 法律安全服务"
                break
            }
            return title + "    "+item[0].data
          }
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },

    //代理机构分析报告
    getAgentAnnualApplicationTrendConfig(){
      let year = []
      let count = []
      let year2Count = new Map()
      this.agentAnnualApplicationTrendData.forEach(item=>{
        year2Count.set(item.year, item.agentCount)
      })

      let startYear = new Date().getFullYear() - 10
      for(let i = 0; i < 10; i++){
        if (year2Count.has(startYear)){
          year.push(startYear)
          count.push(year2Count.get(startYear))
          startYear++
        }
        else {
          year.push(startYear)
          count.push(0)
          startYear++
        }
      }

      return {
        backgroundColor:'#ffffff',
        xAxis: {
          type: 'category',
          data: year,
          axisLabel:{
            interval:0,
          },
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        series: {
          data: count,
          type: 'line',
          symbol: 'none',
          smooth:true,
          lineStyle:{
            color: '#466CF4',
          },
          areaStyle: {
            color: '#E0EBFE',
          },
          emphasis: {
            focus:'series'
          }
        },
        emphasis: {
          areaStyle: {
            color: '#E0EBFE',
          },
        },
        tooltip: {
          show:true,
          trigger: 'axis',
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getAgentRegionalAgencyVolumnConfig(){
      let that = this
      let color = ['#0052D9', '#029CD4', '#2BA471', '#F5BA18',  "#E37318", '#F58218']
      let area = []
      let count1 = []
      let count2 = []
      let count3 = []
      let count4 = []
      let count5 = []
      let count6 = []
      for (let i = 0; i < this.agentRegionalAgencyVolumnData.length; i++){
        area.push(this.agentRegionalAgencyVolumnData[i].area)
        let total = 0
        for(let j = 0; j < 5; j++){
          switch (j){
            case 0:
              if(this.agentRegionalAgencyVolumnData[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount
                count1.push(this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount)
              }
              else{
                count1.push(0)
              }
              break
            case 1:
              if(this.agentRegionalAgencyVolumnData[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount
                count2.push(this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount)
              }
              else{
                count2.push(0)
              }
              break
            case 2:
              if(this.agentRegionalAgencyVolumnData[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount
                count3.push(this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount)
              }
              else{
                count3.push(0)
              }
              break
            case 3:
              if(this.agentRegionalAgencyVolumnData[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount
                count4.push(this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount)
              }
              else{
                count4.push(0)
              }
              break
            case 4:
              if(this.agentRegionalAgencyVolumnData[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount
                count5.push(this.agentRegionalAgencyVolumnData[i].applicantVOList[j].appCount)
              }
              else{
                count5.push(0)
              }
              break
          }
        }
        let count6Number = this.agentRegionalAgencyVolumnData[i].agentCount - total
        count6.push(count6Number)
      }

      let count6PushFlag = false
      count6.forEach(item=>{
        if (item > 0){
          count6PushFlag = true
        }
      })

      let mySeries = []
      if (count6PushFlag){
        mySeries.push({
          type: 'bar',
          stack: 'Total',
          symbol:'none',
          emphasis: {
            focus: 'series'
          },
          barWidth:'30%',
          data: count6
        })
      }
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count1
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count2
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count3
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count4
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count5
      })

      return {
        xAxis:{
          type:'category',
          data: area,
          axisLabel:{
            interval:0,
          }
        },
        yAxis:{
          type: 'value',
          minInterval:1,
        },
        color:color,
        series:mySeries,
        tooltip: {
          show:true,
          trigger: 'axis',
          formatter:function (params){
            let result = ""
            for(let i = 0; i < params.length; i++){
              if (params[i].data != 0){
                result += params[i].marker + ' '+params[i].data+'<br>'
              }
            }
            return params[0].name +'<br/>'+result
          }
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getAgentTrademarkStatusVolumnConfig(){
      let that = this
      let legend1 = ['已注册','无效','注册申请审查中','驳回复审审查中', '撤销/无效宣告申请审查中']
      let legend2 = ['公告中','异议审查中']
      let color = ["#0052D9", "#2BA471",  "#E37318", "#D54941", "#D54941", "#029CD4", "#F5BA18"]
      let data = []

      this.agentTrademarkStatusVolumnData.forEach(item=>{
        data.push({
          name:item.status,
          value:item.statusCount
        })
      })

      return {
        tooltip: {
          trigger: 'item'
        },
        color:color,
        series:{
          type: 'pie',
          radius: ['25%', '40%'],
          center:['20%', '50%'],
          label: { show: false, position: 'center' },
          data: data,
          emphasis: {
            label:{
              formatter:`{c}`,
              show: true,
              fontsize: 14,
              color: '#6285E5'
            }
          },
          itemStyle:{
            borderWidth:1,
            borderColor:'rgb(255,255,255)'
          }
        },
        legend:[
          {
            type: 'plain',
            orient: 'vertical',
            show: true,
            right: "90",
            top: "40",
            data: legend1,
            itemGap:20,
            icon:'circle',
            formatter:function (item){
              let count = 0
              that.agentTrademarkStatusVolumnData.forEach(it=>{
                if (it.status == item){
                  count = it.statusCount
                }
              })
              var arr = [
                '{name|'+item+"}",
                '{count|'+count+"}"
              ]
              return arr.join('    ')
            },
            textStyle: {
              rich: {
                count:{
                  color:"#6285E5"
                }
              }
            }
          },
          {
            type: 'plain',
            orient: 'vertical',
            show: true,
            right: "right",
            top:"40",
            data: legend2,
            itemGap:20,
            icon:'circle',
            formatter:function (item){
              let count = 0
              that.agentTrademarkStatusVolumnData.forEach(it=>{
                if (it.status == item){
                  count = it.statusCount
                }
              })
              var arr = [
                '{name|'+item+"}",
                '{count|'+count+"}"
              ]
              return arr.join('    ')
            },
            textStyle: {
              rich: {
                count:{
                  color:"#6285E5"
                }
              }
            }
          }
        ]
      }
    },
    getAgentCategoryConfig(){
      let aveVal = 0
      this.agentCategoryData.forEach(item=>{
        aveVal += item[1]
      })
      aveVal /= this.agentCategoryData.length

      return {
        xAxis: {
          type:'category',
          axisLabel:{
            interval:0,
          }
        },
        yAxis: {
          minInterval:1,
        },
        series: {
          symbolSize: function (val, params){
            return 20 * (val[1]/aveVal)
          },
          data: this.agentCategoryData,
          type: 'scatter',
        },
        tooltip:{
          show:true,
          trigger: 'item',
          formatter:function (item){
            let title = ""
            switch (item.data[0]){
              case 1:
                title="01类 工业用化学品"
                break
              case 2:
                title="02类 颜料油漆树脂"
                break
              case 3:
                title="03类 清洁化妆用品"
                break
              case 4:
                title="04类 油脂燃料能源"
                break
              case 5:
                title="05类 医药卫生用品"
                break
              case 6:
                title="06类 金属五金器具"
                break
              case 7:
                title="07类 机器机械设备"
                break
              case 8:
                title="08类 餐具手工器具"
                break
              case 9:
                title="09类 科学电子设备"
                break
              case 10:
                title="10类 医疗护理器械"
                break
              case 11:
                title="11类 照明空调卫浴"
                break
              case 12:
                title="12类 交通运输工具"
                break
              case 13:
                title="13类 军火烟火爆竹"
                break
              case 14:
                title="14类 珠宝首饰钟表"
                break
              case 15:
                title="15类 乐器及其配件"
                break
              case 16:
                title="16类 办公教学用品"
                break
              case 17:
                title="17类 塑料橡胶制品"
                break
              case 18:
                title="18类 箱包皮具皮革"
                break
              case 19:
                title="19类 非金属的建材"
                break
              case 20:
                title="20类 家具容器骨石"
                break
              case 21:
                title="21类 家居厨房器具"
                break
              case 22:
                title="22类 绳网帐篷帆布"
                break
              case 23:
                title="23类 纺织用纱和线"
                break
              case 24:
                title="24类 日常用纺织品"
                break
              case 25:
                title="25类 服装服饰鞋帽"
                break
              case 26:
                title="26类 花边配饰假发"
                break
              case 27:
                title="27类 地毯席类墙纸"
                break
              case 28:
                title="28类 游戏运动器具"
                break
              case 29:
                title="29类 蔬果肉蛋奶油"
                break
              case 30:
                title="30类 咖啡面点调料"
                break
              case 31:
                title="31类 水果蔬菜花卉"
                break
              case 32:
                title="32类 啤酒饮料糖浆"
                break
              case 33:
                title="33类 含酒精的饮料"
                break
              case 34:
                title="34类 烟草烟具火柴"
                break
              case 35:
                title="35类 广告办公销售"
                break
              case 36:
                title="36类 保险金融服务"
                break
              case 37:
                title="37类 建筑修理安装"
                break
              case 38:
                title="38类 电信通信服务"
                break
              case 39:
                title="39类 运输仓储服务"
                break
              case 40:
                title="40类 材料处理服务"
                break
              case 41:
                title="41类 教育娱乐文体"
                break
              case 42:
                title="42类 设计咨询开发"
                break
              case 43:
                title="43类 饮食住宿看护"
                break
              case 44:
                title="44类 医疗美容园林"
                break
              case 45:
                title="45类 法律安全服务"
                break
            }
            return title+": "+item.data[1]
          }
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getAgentAnnualApplicationTrendForeignConfig(){
      let year = []
      let count = []
      let year2Count = new Map()
      this.agentAnnualApplicationTrendDataForeign.forEach(item=>{
        year2Count.set(item.year, item.agentCount)
      })

      let startYear = new Date().getFullYear() - 10
      for(let i = 0; i < 10; i++){
        if (year2Count.has(startYear)){
          year.push(startYear)
          count.push(year2Count.get(startYear))
          startYear++
        }
        else {
          year.push(startYear)
          count.push(0)
          startYear++
        }
      }

      return {
        backgroundColor:'#ffffff',
        xAxis: {
          type: 'category',
          data: year,
          axisLabel:{
            interval:0,
          },
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval:1,
          axisLine:{
            lineStyle:{
              color: '#8186A5'
            }
          }
        },
        series: {
          data: count,
          type: 'line',
          symbol: 'none',
          smooth:true,
          lineStyle:{
            color: '#466CF4',
          },
          areaStyle: {
            color: '#E0EBFE',
          },
          emphasis: {
            focus:'series'
          }
        },
        emphasis: {
          areaStyle: {
            color: '#E0EBFE',
          },
        },
        tooltip: {
          show:true,
          trigger: 'axis',
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
    getAgentRegionalForeignConfig(){
      let that = this
      let color = ['#0052D9', '#029CD4', '#2BA471', '#F5BA18',  "#E37318", '#F58218']
      let area = []
      let count1 = []
      let count2 = []
      let count3 = []
      let count4 = []
      let count5 = []
      let count6 = []
      for (let i = 0; i < this.agentRegionalAgencyVolumnDataForeign.length; i++){
        area.push(this.agentRegionalAgencyVolumnDataForeign[i].country)
        let total = 0
        for(let j = 0; j < 5; j++){
          switch (j){
            case 0:
              if (this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount
                count1.push(this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount)
              }
              else {
                count1.push(0)
              }
              break
            case 1:
              if (this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount
                count2.push(this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount)
              }
              else {
                count2.push(0)
              }
              break
            case 2:
              if (this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount
                count3.push(this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount)
              }
              else {
                count3.push(0)
              }
              break
            case 3:
              if (this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount
                count4.push(this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount)
              }
              else {
                count4.push(0)
              }
              break
            case 4:
              if (this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList.length > j){
                total += this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount
                count5.push(this.agentRegionalAgencyVolumnDataForeign[i].applicantVOList[j].appCount)
              }
              else {
                count5.push(0)
              }
              break
          }
        }
        let count6Number = this.agentRegionalAgencyVolumnDataForeign[i].agentCount - total
        count6.push(count6Number)
      }

      let count6PushFlag = false
      count6.forEach(item=>{
        if (item > 0){
          count6PushFlag = true
        }
      })

      let mySeries = []
      if (count6PushFlag){
        mySeries.push({
          type: 'bar',
          stack: 'Total',
          symbol:'none',
          emphasis: {
            focus: 'series'
          },
          barWidth:'30%',
          data: count6
        })
      }
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count1
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count2
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count3
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count4
      })
      mySeries.push({
        type: 'bar',
        stack: 'Total',
        symbol:'none',
        emphasis: {
          focus: 'series'
        },
        barWidth:'30%',
        data: count5
      })

      return {
        xAxis:{
          type:'category',
          data: area,
          axisLabel:{
            interval:0,
          }
        },
        yAxis:{
          type: 'value',
          minInterval:1,
        },
        color:color,
        series:mySeries,
        tooltip: {
          show:true,
          trigger: 'axis',
          formatter:function (params){
            let result = ""
            for(let i = 0; i < params.length; i++){
              if (params[i].data != 0){
                result += params[i].marker + ' '+params[i].data+'<br>'
              }
            }
            return params[0].name +'<br/>'+result
          }
        },
        grid:{
          x:50,
          x2:20,
          y:20,
          y2:40
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.queryAnalysisReport{
  display: inline-block;
  width: 580px;
  text-align: center;
  z-index: 100;
  position: relative;

  .downloadButton{
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
    z-index: 9999;
  }

  .analysisTitle{
    position: relative;
    margin: 0 35px;
    padding: 20px 0;

    font-weight: bold;
    font-size: 16px;
    color: #130D54;
    line-height: 19px;

    border-bottom: 1px solid #6C86E3;
  }
  .analysisContentItem{
    margin: 0 35px;
    padding: 0 0 20px;
    text-align: left;
    border-bottom: 1px solid #D0D8F5;

    .analysisContentTitle{
      position: relative;
      padding: 15px 0 15px 13px;
      font-weight: bolder;
      font-size: 14px;
    }
    .analysisContentTitle:before{
      position: absolute;
      content: "";
      top: 20px;
      left: 0;
      width: 4px;
      height: 14px;
      background-color: #4A6EF0;
    }

    .trademarkAgentTotal{
      position: relative;
      span{
        position: absolute;
        top: 15px;
        left: 40px;
        font-weight: bolder;
        font-size: 30px;
        letter-spacing: 1px;
        color: #112E95;
      }
    }

    .analysisContentTable{
      text-align: center;

      table{
        border-collapse: collapse;
        scrollbar-width: none;
        &.active{
          height: 270px;
        }
        overflow: auto;
        display: inline-block;

        th{
          position: sticky;
          top: 0;
          font-size: 14px;
          font-weight: bolder;
          letter-spacing: 1px;
          padding: 10px;
          background-color: rgb(226,239,255);
        }
        th:first-of-type{
          border-top-left-radius: 10px;
        }
        th:last-of-type{
          border-top-right-radius: 10px;
        }
        tbody{
          display: block;
          width: 100%;
          height: 200px;
          overflow-y: auto;
        }
        td{
          padding: 5px;
          font-size: 13px;
          border: #E3E8EE solid 1px;
          color: #666666;
        }

        tr{
          td:nth-of-type(1){
            width: 60px;
          }
          td:nth-of-type(2){
            width: 80px;
          }
          td:nth-of-type(3){
            width: 210px;
          }
          td:nth-of-type(4){
            width: 60px;
          }
        }

        tr:last-of-type{
          td:first-of-type{
            border-bottom-left-radius: 10px;
          }
          td:last-of-type{
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    .analysisContentChart{
      width: 100%;
      height: 250px;
    }

    .middleLine{
      position: relative;
    }
    .middleLine:after{
      position: absolute;
      content: "";
      background-color: #CBD2EA;
      height: 50px;
      width: 1px;
      left: 42%;
      top:40%;

    }

    .middleLine2{
      position: relative;
    }
    .middleLine2:before{
      position: absolute;
      content: "";
      background-color: #CBD2EA;
      height: 50px;
      width: 1px;
      left: 50%;
      top:40%;
    }

    .analysisContentTitle2{
      font-weight: normal;
      padding: 5px 0 5px 18px;
      font-size: 13px;
    }
    .agentRegistrationPassRate{
      margin-left: 60px;
    }
    .agentRejectionRate{
      margin-left: 60px;
    }
    ::v-deep .el-progress__text{
      line-height: 30px;
      font-size: 24px!important;
      letter-spacing: 1px;
      font-weight: bolder;
    }
    ::v-deep .agentRegistrationPassRate .el-progress__text{
      color: rgba(245,186,24,1)!important;
    }
    ::v-deep .agentRejectionRate .el-progress__text{
      color: rgba(0,82,217, .8)!important;
    }
    ::v-deep .agentRegistrationPassRate .el-progress__text::after{
      content: "注册率";
      color: #666666!important;
      font-size: 14px!important;
    }
    ::v-deep .agentRejectionRate .el-progress__text::after{
      content: "驳回率";
      color: #666666!important;
      font-size: 14px!important;
    }
  }
  .myNote{
    text-align: center;
    font-size: 12px;
    margin: 20px;
  }
  .myNote2{
    text-align: left;
    font-size: 12px;
    margin-left: 20px;
  }
}
.queryAnalysisReport:before{
    position: absolute;
    content: "";
    top: -15px;
    left: 0;
    height: 400px;
    width: calc( 100% - 16px );
    background-image: linear-gradient(to bottom left, rgba(74, 110, 240, .8), transparent 51%);
    z-index: 0;
}
</style>
